import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header'; 
import ScrollToTop from './components/ScrollToTop'; 
import LoadingSpinner from './LoadingSpinner'; 

const Footer = lazy(() => import('./components/Footer'));
const HomePage = lazy(() => import('./components/HomePage'));
const AboutPage = lazy(() => import('./components/AboutPage'));
const PortfolioPage = lazy(() => import('./components/PortfolioPage'));
const ContactPage = lazy(() => import('./components/ContactPage'));
const CategoryPage = lazy(() => import('./components/CategoryPage'));
const RequestSamples = lazy(() => import('./components/RequestSamples'));
const AllPortfolio = lazy(() => import('./components/AllPortfolio'));

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Suspense fallback={<LoadingSpinner />}> {/* Use the loading spinner here */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/portfolio/all" element={<AllPortfolio />} />
          <Route path="/portfolio/:category" element={<CategoryPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/samples" element={<RequestSamples />} />
        </Routes>
        <Footer /> 
      </Suspense>
    </Router>
  );
}

export default App;
